import axios from 'axios';

const login = async (data) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/login`, { data });
        localStorage.setItem('wise_webhook_viewer_token', response.data.token); // Store token in local storage
        return response.data;
    } catch (error) {
        throw error;
    }
};

const logout = () => {
    localStorage.removeItem('wise_webhook_viewer_token'); // Remove token from local storage
};

const isAuthenticated = () => {
    return localStorage.getItem('wise_webhook_viewer_token') !== null; // Check if token exists
};

const getToken = () => {
    try {
        return localStorage.getItem('wise_webhook_viewer_token');
    } catch (error) {
        throw error;
    }
    
};

export { login, logout, isAuthenticated, getToken };