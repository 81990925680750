import React from 'react';

export default function Header( {appLogin, appLogout, isLoggedIn} ) {

    return (
        <div className='d-flex justify-content-between'>
            <h1 className='mt-0 mb-0 text-left'>Wise Platform API Webhooks</h1>
            <div>
            { isLoggedIn ? (
            <div className='d-flex'>
                <button className='environment-flag'>SANDBOX</button>
                <button className='logout ml-3 w-100' onClick={appLogout}>LOGOUT</button>
            </div>
            ) : (
                <button className='environment-flag' onClick={appLogin}>LOGIN</button>
            )}
            
            </div>  
        </div>
    );
}