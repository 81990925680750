import React from 'react';

export default function Filter({ onFilterChange, availableTypes }) {

    return (
        <div className='d-flex align-items-center justify-content-between'>
            <h5 className='mt-0 mb-0 pt-2 text-left small'>Webhook data here has been generated from API calls made to the sandbox. Only showing the first 250 webhooks.</h5>
            <div className='mt-3'>
                <select className='w-100 custom-select font-family-sys' id="eventType" onChange={onFilterChange}>
                    <option value="all">Show all event types</option>
                        { availableTypes.map(eventType => (
                    <option key={eventType} value={eventType}>{eventType}</option>
                    ))}
                </select>
            </div>
        </div>
    );
}