import React, { useState } from 'react';
import './Login.css'; // Import CSS for styling

const Login = ({ isOpen, onClose, onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = (event) => {
        event.preventDefault();
        onLogin(username, password);
    };

    return (
        <div className={`login-modal ${isOpen ? 'open' : ''}`}>
            <div className="login-modal-content">
                <div className='d-flex justify-content-between align-items-center mb-3'>
                    <h2 className='mt-0 mb-0'>Login</h2>
                    <span className="login-modal-close" onClick={onClose}>&times;</span>
                </div>
                
                <form className='text-left' onSubmit={handleLogin}>
                    <label>
                    Username:
                    <input
                        className='label-input'
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    </label>
                    <label>
                    Password:
                    <input
                        className='label-input'
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    </label>
                    <button className='w-100' type="submit">Login</button>
                </form>
            </div>
        </div>
    );
};

export default Login;