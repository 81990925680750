import React from 'react';
import './Webhook.css';

export default function Webhook ({ webhookData, viewPayload }) {
        
    return (
        <tr key={webhookData['_id']['$oid']}>
            <td>{webhookData.event_time}</td>
            <td>{webhookData.event_type}</td>
            <td>{webhookData.schema_version}</td>
            <td>{webhookData.subscription_id}</td>
            <td className='text-center'><button className="payload-button" onClick={() => viewPayload(webhookData.payload)}>View</button></td>
        </tr>
    );
}