import React, { useState } from 'react';
import WebhookTable from './components/WebhookTable/WebhookTable';
import Login from './components/Login/Login';
import {login, logout, isAuthenticated } from './utils/AuthService';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import './App.css';

function App() {

  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(isAuthenticated());

  const handleLoginToggle = () => {
      setIsLoginOpen(!isLoginOpen);
  };

  const handleLogin = async (username, password) => {
      try {
        let data = {
          username: username,
          password: password
        }
        await login(data);
        setIsLoggedIn(true);
        setIsLoginOpen(false);
      } catch (error) {
          console.error('Error logging in:', error);
          alert('Login failed. Please try again.');
      }
  };

  const handleLogout = () => {
      logout();
      setIsLoggedIn(false);
  }

  return (
    <div className="App">
      <Header appLogin={handleLoginToggle} appLogout={handleLogout} isLoggedIn={isLoggedIn} ></Header>
      { isLoggedIn ? (
        <WebhookTable />
      ) : (
      <Login isOpen={isLoginOpen} onClose={handleLoginToggle} onLogin={handleLogin} />
      )}
      <Footer></Footer>
    </div>
  );
}

export default App;
