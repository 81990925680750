import React, { useEffect } from 'react';
import hljs from 'highlight.js/lib/core';
import "highlight.js/styles/github.css";
import json from 'highlight.js/lib/languages/json';
import './PayloadModal.css';

export default function PayloadModal({selectedPayload, handleClickOutsideModal, closeModal}) {

    useEffect(() => {
        hljs.registerLanguage('json', json);
        hljs.highlightAll();
        return () => {
            hljs.unregisterLanguage('json');
        }
    }, [selectedPayload])

    return (
        selectedPayload && (
            <div className="modal" onClick={handleClickOutsideModal} tabIndex={0}>
                <div className="modal-content">
                    <div className='d-flex align-items-center justify-content-between'>
                        <h3>{selectedPayload.event_type} - {selectedPayload.sent_at}</h3>
                        <span className="close" onClick={closeModal}>&times;</span>
                    </div>
                    <pre>
                        <code className='font-size-small'>{JSON.stringify(selectedPayload, null, 4)}</code>
                    </pre>
                </div>
            </div>
        )
    );
};