import React, { useState, useEffect } from 'react';
import './WebhookTable.css'; // Import CSS for styling
import axios from 'axios';
import Filter from '../Filter/Filter';
import Webhook from '../Webhook/Webhook';
import PayloadModal from '../PayloadModal/PayloadModal';
import { getToken } from '../../utils/AuthService';

const WebhookTable = () => {
    // State variables
    const [selectedPayload, setSelectedPayload] = useState(null);
    const [webhooks, setWebhooks] = useState([]);
    const [eventTypes, setEventTypes] = useState([]);
    const [webhookLoading, setWebhookLoading] = useState(true);
    const [eventTypeLoading, setEventTypeLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedEventType, setSelectedEventType] = useState('all');

    // Constants
    const token = getToken();

    // For when I want to add polling...
    // useEffect(() => {
    //     fetchEventTypes();
    //     fetchWebhooks(selectedEventType);
    //     const interval = setInterval(() => {
    //         fetchEventTypes();
    //         fetchWebhooks(selectedEventType);
    //     }, 10000);
    //     return () => {
    //         clearInterval(10000);
    //     }
    // }, []);

    useEffect(() => {
            fetchEventTypes();
            fetchWebhooks(selectedEventType);
    }, []);

        
    const fetchWebhooks = async (event_type = 'all') => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/webhooks?event_type=${event_type}`, { headers: { 'Authorization': `Bearer ${token}` } });
            setWebhooks(response.data);
            setWebhookLoading(false);
        } catch (error) {
            console.error('Error fetching webhooks:', error);
            setError(error);
            setWebhookLoading(false);
        }
    };

    const fetchEventTypes = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/event-types`,  { headers: { 'Authorization': `Bearer ${token}` } });
            setEventTypes(response.data);
            setEventTypeLoading(false);
        } catch (error) {
            console.error('Error fetching event types:', error);
            setError(error);
            setEventTypeLoading(false);
        }
    };

    const checkLoadingComplete = (eventTypeLoading, webhookLoading) => {
        if (eventTypeLoading === false && webhookLoading === false) {
            return true;
        }
        return false;
    }
        

    const handleFilterChange = (event) => {
        let filterValue = event.target.value;
        let encodedValue = encodeURIComponent(filterValue);
        setSelectedEventType(encodedValue);
        fetchWebhooks(encodedValue);
    };

    const showPayload = (payload) => {
        setSelectedPayload(payload);
    };

    const closeModal = () => {
        setSelectedPayload(null);
    };

    const handleClickOutsideModal = (event) => {
        if (event.target === event.currentTarget) {
            closeModal();
        }
    };

    return (
        <div>
            { !checkLoadingComplete(eventTypeLoading, webhookLoading) ? (
                <p>Loading webhooks ...</p>
            ) : error ? (
                <p>Error loading webhooks: {error.message} </p>
            ) : (
                <div>
                <Filter onFilterChange={handleFilterChange} availableTypes={eventTypes} />
                <table className="webhook-table">
                    <thead>
                        <tr>
                            <th>Event Time (GMT)</th>
                            <th>Event Type</th>
                            <th>Version</th>
                            <th>Subscription ID</th>
                            <th>Payload</th>
                        </tr>
                    </thead>
                    <tbody>
                        {webhooks.map((webhook, index) => (
                            <Webhook key={index + 1} webhookData={webhook} viewPayload={showPayload} />
                        ))}
                    </tbody>
                </table>

                <PayloadModal selectedPayload={selectedPayload} handleClickOutsideModal={handleClickOutsideModal} closeModal={closeModal} />
                </div>
            )}
        </div>
    )
};

export default WebhookTable;